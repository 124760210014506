import './App.css';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon
} from "react-share";
import {MetaTags} from "react-meta-tags";

function App() {
    const shareUrl = "https://reactshare.jmdemo.hu";
    return (
        <div className="App">
            <MetaTags>
                <title>SEO Friendly title</title>
                <meta name="description" content="SEO friendly meta description"/>
                <meta property="og:title" content="SEO friendly JM web React sharing example"/>
                <meta property="og:image" content="./jazz-password.jpg"/>
            </MetaTags>
            <header className="App-header">
                <FacebookShareButton
                    url={shareUrl}>
                    <FacebookIcon/>
                </FacebookShareButton>
                <TwitterShareButton
                    url={shareUrl}
                    title={'Direct Share-friendly TwitterTitle!'}>
                    <TwitterIcon/>
                </TwitterShareButton>
                <LinkedinShareButton
                    url={shareUrl}
                    title={'Direct Share-friendly LinkedIN Title!'}
                    summary={'Direct Share-friendly description text.'}
                    source={'Direct Share-friendly app name!'}>
                    <LinkedinIcon/>
                </LinkedinShareButton>
            </header>
        </div>
    );
}

export default App;
